<template>
  <main class="max-w-[1000px] -mt-8 m-auto w-full">
    <div
      class="fixed bg-bleu/50 inset-0 flex justify-center items-center z-50"
      v-if="done"
    >
      <div class="bg-jaune w-full mx-8 max-w-xl h-96 shadow-2xl rounded-xl">
        <img
          src="@/assets/images/groslogoannexe.png"
          class="w-48 m-auto mt-4"
        />
        <p class="text-bleu px-6 text-center">
          Nous avons bien pris en compte votre demande.<br />Celle-ci sera
          traitée rapidement.
        </p>
        <p class="w-full text-center my-6">
          <button
            type="button"
            class="button bleu m-auto"
            @click="
              done = false;
              form = {};
            "
          >
            Fermer
          </button>
        </p>
      </div>
    </div>
    <div class="w-full text-center mt-12 text-bleu text-lg desktop:text-3xl">
      <img
        src="@/assets/images/contact.png"
        class="m-auto desktop:-mt-8 mb-8 w-[250px] desktop:w-[300px]"
      />
      <form @submit.prevent="setMessage">
        <div class="desktop:flex justify-between desktop:flex-wrap w-full px-8">
          <div class="form-item desktop:!w-[49%]">
            <label>Nom **</label>
            <input v-model="form.lastname" type="text" />
            <span
              v-show="error.lastname"
              class="text-red-500 text-sm inline-block w-full"
              >{{ error.lastname }}</span
            >
          </div>
          <div class="form-item desktop:!w-[49%]">
            <label>Prénom **</label>
            <input v-model="form.firstname" type="text" />
            <span v-if="error.firstname">{{ error.firstname }}</span>
          </div>
          <div class="form-item desktop:!w-full">
            <label>Email **</label>
            <input v-model="form.email" type="email" />
            <span v-if="error.email">{{ error.email }}</span>
          </div>
          <div class="form-item !w-full">
            <select v-model="form.type" class="pt-4 -ml-4">
              <option value="Type de demande **">Type de demande **</option>
              <option value="Problème technique lors de ma participation">
                Problème technique lors de ma participation
              </option>
              <option value="Le site du jeu ne fonctionne pas">
                Le site du jeu ne fonctionne pas
              </option>

              <option
                value="Je n'ai pas reçu l'email de confirmation de ma participation"
              >
                Je n'ai pas reçu l'email de confirmation de ma participation
              </option>
              <option value="Demande d'information sur le jeu">
                Demande d'information sur le jeu
              </option>

              <option value="Je n'ai toujours pas reçu mon lot">
                Je n'ai toujours pas reçu mon gain
              </option>

              <option
                value="J'ai déménagé, que dois-je faire pour recevoir mon lot?"
              >
                J'ai déménagé, que dois-je faire pour recevoir mon gain?
              </option>
              <option
                value="J'ai changé d'adresse mail, que dois-je faire pour recevoir mon lot?"
              >
                J'ai changé d'adresse mail, que dois-je faire pour recevoir mon
                gain?
              </option>
              <option
                value="Formulation d'une observation/réclamation relative aux modalités du jeu"
              >
                Formulation d'une observation/réclamation relative aux modalités
                du jeu
              </option>
              <option value="Autre">Autre</option>
            </select>
            <span v-if="error.type">{{ error.type }}</span>
          </div>
          <div class="form-item !w-full">
            <label>Objet **</label>
            <input v-model="form.objet" type="text" />
            <span v-if="error.objet">{{ error.objet }}</span>
          </div>
          <div class="form-item !w-full">
            <label>Message **</label>
            <textarea class="!p-0" v-model="form.message" />
            <span v-if="error.message">{{ error.message }}</span>
          </div>
        </div>
        <div class="px-8 w-full text-left text-sm mb-8">
          ** Champs obligatoires
        </div>
        <div class="mb-8">
          <button class="button bleu" type="submit">J'envoie</button>
        </div>
      </form>
    </div>
    <div class="px-8 text-bleu text-xs mobile:text-sm text-center">
      *Etude OpinionWay du 6 au 7 mai 2024, 1097 répondants, catégorie sucre
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactView",
  data() {
    return {
      error: {
        lastname: "",
        firstname: "",
        email: "",
        type: "",
        objet: "",
        message: "",
      },
      form: {
        type: "Type de demande **",
      },
      done: false,
    };
  },
  methods: {
    async setMessage(e) {
      try {
        e.preventDefault();

        if (!this.form.lastname || this.form.lastname.length < 1) {
          this.error.lastname = "Le champ nom est obligatoire";
          return;
        }
        if (!this.form.firstname || this.form.firstname.length < 1) {
          this.error.firstname = "Le champ prénom est obligatoire";
          return;
        }
        if (!this.form.email || this.form.email.length < 1) {
          this.error.email = "Le champ email est obligatoire";
          return;
        }
        if (!this.form.type || this.form.type === "Type de demande **") {
          this.error.type = "Le champ type de demande est obligatoire";
          return;
        }
        if (!this.form.objet || this.form.objet.length < 1) {
          this.error.objet = "Le champ objet est obligatoire";
          return;
        }
        if (!this.form.message || this.form.message.length < 1) {
          this.error.message = "Le champ message est obligatoire";
          return;
        }

        let formDatas = {
          to: "herve.colard@promo.dev",
          subid: null,
          operationId: "667400372f7d0b3305a4cd2f",
          nom: this.form.lastname,
          prenom: this.form.firstname,
          email: this.form.email,
          demande: this.form.demande,
          objet: this.form.objet,
          message: this.form.message,
          type: this.form.type,
        };

        axios
          .post(
            "https://gabapi.lucien.ai/api/operations-custom/promodev-send-contact/sendmail",
            formDatas
          )
          .then((response) => {
            this.loadingState = false;
            this.dialogState = true;
            console.log(response);
          });

        let email = {
          email: this.form.email,
        };

        await axios.post(
          "https://gabapi.lucien.ai/api/operations-custom/2024-lonsdale-166/confirm_contact",
          email
        );

        this.done = true;
      } catch (error) {
        console.error(error);
        this.done = true;
      }
    },
  },
};
</script>
<style scoped>
span {
  @apply block text-red-500 text-base text-left w-full font-bold;
}
.form-item {
  @apply border-4 px-4 rounded-xl border-bleu relative mb-4 text-xl w-full;
}

select,
textarea,
input {
  @apply bg-transparent w-full border-0 mb-2 -mt-2 outline-none border-none focus:ring-0 text-lg desktop:text-xl;
}

label {
  @apply inline-block w-full bg-transparent m-0 p-0 outline-none border-none focus:ring-0 text-left py-1;
}
</style>
