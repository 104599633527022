var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"relative pt-8 desktop:pt-0 desktop:h-36 desktop:flex desktop:justify-center desktop:items-center"},[_c('div',{staticClass:"w-full absolute h-12 bottom-0 bg-bleuclair"}),_c('ul',{staticClass:"flex justify-center desktop:justify-between items-center w-full m-auto text-center z-10 desktop:pt-8 text-sm desktop:text-base max-w-4xl 2xl:max-w-5xl desktop:absolute bottom-8"},[_c('li',{staticClass:"text-center hidden desktop:block"},[_c('a',{attrs:{"href":"https://www.beghin-say.fr/","target":"_blank"}},[_c('inline-svg',{staticClass:"w-11 m-auto",attrs:{"src":require(`@/assets/vectors/logo.svg`)}})],1)]),_vm._m(0),_c('li',{staticClass:"desktop:hidden block px-1"},[_vm._v(".")]),_vm._m(1),_c('li',{staticClass:"desktop:hidden block px-1"},[_vm._v(".")]),_vm._m(2),_vm._m(3)]),_c('p',{staticClass:"relative z-50 desktop:hidden flex justify-center gap-3 my-4"},[_c('a',{attrs:{"href":"https://www.beghin-say.fr/","target":"_blank"}},[_c('inline-svg',{staticClass:"w-9 m-auto mr-4",attrs:{"src":require(`@/assets/vectors/logo.svg`)}})],1),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://oxoformdocs.fra1.digitaloceanspaces.com/667400372f7d0b3305a4cd2f/reglement.pdf","target":"_blank"}},[_vm._v("Règlement")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://oxoformdocs.fra1.digitaloceanspaces.com/667400372f7d0b3305a4cd2f/politique.pdf","target":"_blank"}},[_vm._v("Confidentialité & cookies")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://oxoformdocs.fra1.digitaloceanspaces.com/667400372f7d0b3305a4cd2f/mentions.pdf","target":"_blank"}},[_vm._v("Mentions légales")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"gap-6 2xl:gap-12 hidden desktop:flex"},[_c('a',{attrs:{"href":"https://www.facebook.com/BeghinSay/","target":"_blank"}},[_c('img',{staticClass:"w-2 h-2 desktop:w-10 desktop:h-10",attrs:{"src":require("@/assets/images/facebook.png")}})]),_c('a',{attrs:{"href":"https://www.instagram.com/beghinsay/","target":"_blank"}},[_c('img',{staticClass:"w-10 h-10",attrs:{"src":require("@/assets/images/insta.png")}})]),_c('a',{attrs:{"href":"https://www.linkedin.com/showcase/beghin-say-pro/","target":"_blank"}},[_c('img',{staticClass:"w-10 h-10",attrs:{"src":require("@/assets/images/linkedin.png")}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://www.facebook.com/BeghinSay/","target":"_blank"}},[_c('img',{staticClass:"w-7 h-7 desktop:w-8 desktop:h-8",attrs:{"src":require("@/assets/images/facebook.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://www.instagram.com/beghinsay/","target":"_blank"}},[_c('img',{staticClass:"w-7 h-7 desktop:w-8 desktop:h-8",attrs:{"src":require("@/assets/images/insta.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://www.linkedin.com/showcase/beghin-say-pro/","target":"_blank"}},[_c('img',{staticClass:"w-7 h-7 desktop:w-8 desktop:h-8",attrs:{"src":require("@/assets/images/linkedin.png")}})])
}]

export { render, staticRenderFns }