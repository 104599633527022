<template>
  <div
    class="w-full text-center mt-16 xl:text-3xl desktop:text-2xl text-lg px-4"
  >
    <img
      src="@/assets/images/bravo.png"
      class="w-[140px] xl:w-[190px] desktop:w-[170px] m-auto -mt-10 -mb-10 md:mb-0 md:-mt-20 xl:-mt-6"
    />
    <p
      class="py-16 m-auto md:text-center text-left leading-none w-fit max-w-[280px] md:max-w-none md:w-full"
    >
      Vous avez gagné un
      <b class="fond !hidden md:!inline-block">sac à pâtisserie Béghin Say</b>
      <b class="md:!hidden !inline-block fond">sac à pâtisserie</b>
      <b class="md:!hidden !inline-block fond">Béghin Say</b>, pour transporter
      facilement<br class="md:hidden inline-block" />
      tous vos gâteaux.<br /><br />
      Nos équipes procèdent à la validation de<br
        class="md:hidden inline-block"
      />
      votre participation.<br class="hidden md:block" />
      Consultez rapidement<br class="md:hidden inline-block" />
      votre boîte mail pour en savoir plus<br class="md:hidden inline-block" />
      (pensez à vérifier vos spams).
      <span v-if="already"
        ><br class="hidden md:block" />Vous avez
        <br class="md:hidden inline-block" />atteint la limite des
        participations.</span
      >
    </p>

    <img
      src="@/assets/images/sacatarte.png"
      class="w-[150px] md:w-[280px] xl:w-[320px] m-auto -mt-10"
    />

    <img
      v-if="!already"
      src="@/assets/images/pastout.png"
      class="w-[200px] md:w-[300px] xl:w-[400px] m-auto mt-2"
    />
    <p
      v-if="!already"
      class="py-8 xl:py-16 max-w-[280px] md:max-w-none w-fit m-auto md:w-full md:text-center text-left leading-none"
    >
      Rejouez et augmentez vos chances de remporter le
      <b class="fond">robot pâtissier</b> au tirage au sort final en achetant<br />
      un nouveau produit (dans la limite de 2 participations).
    </p>
    <img
      src="@/assets/images/bandeau.png"
      class="w-[250px] md:w-[400px] xl:w-[500px] mb-4 md:mb-12 m-auto mt-6 md:mt-2"
    />
    <p
      class="pb-6 max-w-4xl m-auto md:text-center text-left leading-none w-fit md:w-full md:leading-9"
    >
      Plongez dans notre collection de
      <b class="fond !hidden md:!inline-block">recettes gourmandes</b>
      <b class="md:!hidden !inline-block fond">recettes</b
      ><br class="md:hidden inline-block" />
      <b class="md:!hidden !inline-block fond">gourmandes</b> qui feront
      pétiller les papilles <br class="md:hidden inline-block" />des petits
      comme des grands !
    </p>
    <img
      src="@/assets/images/cafee.png"
      class="w-[200px] md:w-[300px] mb-12 m-auto mt-2"
    />
    <div class="relative w-fit m-auto mb-9">
      <a href="https://www.beghin-say.fr/nos-recettes/" target="blank"
        ><button class="button bleu">JE DÉCOUVRE</button></a
      >
    </div>
    <div class="w-full text-xs md:text-sm md:text-right pr-4 text-center">
      *Etude OpinionWay du 6 au 7 mai 2024, 1097 répondants, catégorie sucre
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  props: {
    already: Boolean,
  },
};
</script>
