<template>
  <main class="max-w-[1500px] -mt-8 m-auto w-full text-bleu text-3xl leading-8">
    <BrElem :already="already" v-if="lot === 'br'" />
    <SacElem :already="already" v-if="lot === 'sacatarte'" />
  </main>
</template>

<script>
import BrElem from "@/components/BrElem";
import SacElem from "@/components/SacElem";

export default {
  name: "App",
  mounted() {
    let lot = sessionStorage.getItem("lot") || "br";
    if (lot == "br" || lot == "sacatarte") {
      this.lot = lot;
    }

    let page = this.$route.query.page;
    if (!page) {
      page = window.pagez || Number(sessionStorage.getItem("page"));
    }

    if (page == 1) {
      this.already = false;
    } else if (page >= 2) {
      this.already = true;
    }
  },
  data() {
    return {
      lot: "br",
      already: true,
    };
  },
  components: {
    BrElem,
    SacElem,
  },
};
</script>
