import Vue from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";

import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import Dayjs from "vue-dayjs";
Vue.use(Dayjs);

import VueKonva from "vue-konva";
Vue.use(VueKonva);

import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";

Vue.use(Simplert);

const pinia = createPinia();
Vue.use(pinia);

import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

import "./assets/style/style.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
