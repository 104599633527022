<template>
  <main class="relative w-full">
    <WaitElem />
  </main>
</template>
<script>
import WaitElem from "@/components/WaitElem";
export default {
  components: {
    WaitElem,
  },
};
</script>
