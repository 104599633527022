<template>
  <header>
    <Drawer @close="toggle" align="right" :closeable="true">
      <div v-if="open" @click="toggle">
        <router-link to="/">
          <img
            src="@/assets/images/groslogoannexe.png"
            class="w-[200px] m-auto"
        /></router-link>
        <ul class="w-full text-center text-bleu">
          <li class="mt-6" @click="toggle">
            <router-link
              to="/pochoir"
              :class="{ 'text-rouge': currentRouteName === 'pochoir' }"
              class="hover:!text-rouge"
              >Personnalisez<br />vos pochoirs</router-link
            >
          </li>
          <li class="mt-6" @click="toggle">
            <a
              href="https://www.beghin-say.fr/nos-recettes/"
              class="hover:text-rouge"
              target="_blank"
              >Nos idées recettes</a
            >
          </li>
          <li class="mt-6" @click="toggle">
            <a
              href="https://www.beghin-say.fr/nos-sucres/types/patisseries/"
              class="hover:text-rouge"
              target="_blank"
              >Nos produits</a
            >
          </li>
          <li class="my-6" @click="toggle">
            <router-link
              class="hover:text-rouge"
              to="/contact"
              :class="{ 'text-rouge': currentRouteName === 'contact' }"
              >Contactez-nous</router-link
            >
          </li>
        </ul>
        <div @click="toggle" class="flex justify-center gap-4 mt-12">
          <a href="https://www.facebook.com/BeghinSay/" target="_blank"
            ><img src="@/assets/images/facebook2.png" class="w-10 h-10"
          /></a>
          <a href="https://www.instagram.com/beghinsay/" target="_blank"
            ><img src="@/assets/images/insta2.png" class="w-10 h-10"
          /></a>
          <a
            href="https://www.linkedin.com/showcase/beghin-say-pro/"
            target="_blank"
            ><img src="@/assets/images/linkedin2.png" class="w-10 h-10"
          /></a>
        </div>
      </div>
    </Drawer>
    <div class="desktop:hidden block rlative">
      <button @click="toggle">
        <img
          src="@/assets/images/menumobile.png"
          class="absolute right-6 top-6 w-10 cursor-pointer"
        />
      </button>
      <router-link to="/">
        <img
          src="@/assets/images/groslogoannexe.png"
          class="w-[195px] m-auto -mt-2"
      /></router-link>
    </div>
    <ul
      class="max-w-6xl medium:max-w-7xl h-[230px] mini:h-[270px] medium:h-[290px] classic:h-72 justify-center m-auto w-full xl:pt-8 text-2xl medium:text-3xl hidden desktop:flex"
    >
      <li class="pt-3 pr-10 xl:pr-0 text-left xl:w-56">
        <router-link
          to="/pochoir"
          class="hover:text-rouge"
          :class="{ 'text-rouge': currentRouteName === 'pochoir' }"
          >Personnalisez<br />vos pochoirs</router-link
        >
      </li>
      <li class="pt-8 text-right">
        <a
          href="https://www.beghin-say.fr/nos-recettes/"
          class="hover:text-rouge"
          target="_blank"
          >Nos idées recettes</a
        >
      </li>
      <li class="text-center">
        <router-link to="/">
          <img
            src="@/assets/images/groslogoannexe.png"
            class="w-[350px] medium:w-[430px] m-auto"
        /></router-link>
      </li>
      <li class="pt-8 xl:w-56 -ml-12 pr-8 xl:pr-0">
        <a
          href="https://www.beghin-say.fr/nos-sucres/types/patisseries/"
          class="hover:text-rouge"
          target="_blank"
          >Nos produits</a
        >
      </li>
      <li class="pt-8">
        <router-link
          class="hover:text-rouge"
          :class="{ 'text-rouge': currentRouteName === 'contact' }"
          to="/contact"
          >Contactez-nous</router-link
        >
      </li>
    </ul>
  </header>
</template>
<style lang="scss">
.vue-simple-drawer .close-btn .leftright,
.vue-simple-drawer .close-btn .rightleft {
  @apply bg-bleu;
}

.vue-simple-drawer.right {
  width: 90% !important;
  background-color: transparent;
  background-image: url(@/assets/images/fond-menu.png);
  background-size: 110% 110%;
  background-position: top -20px left 10px;
  background-repeat: no-repeat;
}

header {
  background-image: url("@/assets/images/haut.png");
  background-position: left top -120px;
  background-repeat: repeat-x;
  background-size: 100% 100%;

  @media (max-width: 950px) {
    background-image: none;
  }
}
</style>
<script>
import Drawer from "vue-simple-drawer";
export default {
  name: "HeaderElem",
  components: {
    Drawer,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
