<template>
  <main class="relative w-full h-full desktop:flex desktop:items-center">
    <div
      class="w-full max-w-[1250px] classic:max-w-[1400px] hd:max-w-[1600px] m-auto"
    >
      <HomeElem />
    </div>

    <p
      class="desktop:text text-sm textshadow text-[11px] desktop:leading-4 leading-3 text-white text-center desktop:text-right w-full absolute bottom-2 right-0 desktop:right-6"
    >
      *Etude OpinionWay du 6 au 7 mai 2024, 1097 répondants, catégorie sucre<br />
      Visuel non contractuel - coloris et modèle du produit sous réserve de
      disponibilité
    </p>
  </main>
</template>
<script>
import HomeElem from "@/components/HomeElem";

export default {
  name: "App",
  data() {
    return {
      page: "wait",
    };
  },
  components: {
    HomeElem,
  },
};
</script>
