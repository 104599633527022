<template>
  <div
    class="w-full text-center mt-8 desktop:mt-16 xl:text-3xl desktop:text-2xl text-xl md:px-4 text-bleuclair"
  >
    <img
      src="@/assets/images/bravo.png"
      class="w-[140px] xl:w-[190px] desktop:w-[170px] m-auto desktop:-mt-20 xl:-mt-6"
    />
    <p
      class="py-8 w-fit max-w-xs md:max-w-none m-auto md:w-full desktop:pt-16 md:text-center text-left leading-none pl-2"
    >
      Vous remportez un
      <b class="!hidden md:!inline-block fond">bon de réduction Béghin Say</b>
      <b class="md:!hidden !inline-block fond">bon de réduction</b>
      <b class="md:!hidden !inline-block fond">Béghin Say</b>,valable sur
      <b>TOUS nos produits</b> <br class="md:hidden inline-block" />dans votre
      enseigne préférée. <br /><br />Consultez rapidement votre boîte mail<br
        class="md:hidden inline-block"
      />
      pour en savoir plus (pensez à vérifier vos
      <br class="md:hidden inline-block" />spams).
      <span v-if="already"
        ><br class="md:block hidden" />Vous avez atteint la limite des
        <br class="md:hidden inline-block" />participations.</span
      >
    </p>

    <img
      v-if="!already"
      src="@/assets/images/pastout.png"
      class="w-[220px] md:w-[400px] xl:w-[450px] m-auto -mt-2 md:mt-2 -mb-6 md:mb-0"
    />
    <p
      v-if="!already"
      class="py-8 xl:py-16 max-w-[300px] mt-4 md:max-w-none w-fit m-auto md:w-full md:text-center text-left leading-none"
    >
      Rejouez et augmentez vos chances de remporter le
      <b class="fond">robot pâtissier</b> au tirage au sort final en achetant
      un<br />
      nouveau produit grâce à votre bon de réduction (dans la limite de 2
      participations).
    </p>
    <div class="relative w-fit m-auto">
      <img
        src="@/assets/images/pochoir.png"
        class="w-full mobile:w-[600px] xl:w-[850px] m-auto -mt-6 mb-28 hidden md:block"
      />
      <img
        src="@/assets/images/pochoir-mobile.png"
        class="w-full max-w-[330px] m-auto -mt-6 mb-28 block md:hidden"
      />
      <div
        class="w-full absolute max-w-[260px] desktop:max-w-[560px] -bottom-8 desktop:-bottom-12 text-center desktop:text-left desktop:pl-48"
      >
        <router-link to="/pochoir"
          ><button class="button bleu !text-lg desktop:!text-2xl -mt-6">
            JE CRÉÉ MON POCHOIR
          </button></router-link
        >
      </div>
    </div>
    <div class="w-full text-xs md:text-sm md:text-right pr-4 text-center">
      *Etude OpinionWay du 6 au 7 mai 2024, 1097 répondants, catégorie sucre
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  props: {
    already: Boolean,
  },
};
</script>
